* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
  --color-ebebeb: #ededed;
  --color-f2f2f2: #f2f2f2; 
}

.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

body {
  font-family: 'Rubik', sans-serif;
  
}

a{
  text-decoration: none;
  display: inline-block;
}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-30 {
  gap: 30px;
}

.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 35px;
}

.button:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921)
}

/* Header Part */

.header-top-strip {
  background-color: var(--color-131921);
  border-bottom: 1px solid var(--color-3b4149);
}

.header-upper {
  background-color: var(--color-131921);
}

.header-bottom {
  background-color: var(--color-232f3e);
}

.input-group-text {
  background-color: var(--color-febd69);
}

.header-upper .header-upper-links img{
  width: 35px;
  height: 35px;
}

.header-bottom .menu-links a,
.header-bottom .dropdown button {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: .3;
  text-transform: uppercase;
}

.header-bottom .dropdown button:focus {
  box-shadow: none;
}

.header-bottom .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}

.header-bottom .dropdown-item {
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149);
}

.header-bottom .dropdown-item:hover {
  background-color: transparent;
  color: var(--color-febd69) !important;
}

/* Header Part End Here */

/* Footer Part */

footer {
  background-color: var(--color-232f3e);
}

footer:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, .1);
}

footer .input-group-text {
  background-color: var(--color-232f3e);
  color: white;
  padding: 8px 17px;
}
/* Footer Part Ends Here */

/* Home Page */
/* Home Wrapper 1 */

.main-banner-content {
  top: 15%;
  left: 5%;
}

.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.main-banner-content h5 {
  font-size: 42px;
  line-height: 64px;
  font-weight: 500;
  letter-spacing: -2px;
  text-transform: none;
}

.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: var(--color-131921)
}

.small-banner-content {
  top: 25%;
  left: 10%;
}

.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: -2px;
  text-transform: none;
}

.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: var(--color-131921)
}

.small-banner {
  width: 49%;
}

/* Home Wrapper 1 Ends Here */

/* Home Wrapper 2 */

.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
}

/* Home Wrapper 2 Ends Here */

/* Categories Section */

.categories {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.categories > div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}

.categories-image {
  height: 140px;
}

.categories > div > div {
  width: 45%;
}

.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3),
.categories > div:nth-child(4) {
  border-bottom: 1px solid var(--color-ededed);
}

.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3),
.categories > div:nth-child(5),
.categories > div:nth-child(6),
.categories > div:nth-child(7) {
  border-right: 1px solid var(--color-ededed);
}

/* Categories Section ends here */

/* Brands Section */

.marquee-wrapper {
  background-color: var(--color-f5f5f7);
}

/* Brands Section Ends Here */

/* Blog Part */

.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}

.blog-card {
  background-color: white;
  /* padding: 20px; */
  border-radius: 10px;
}

.blog-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-content {
  padding: 15px
}

.blog-content.date {
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}

.blog-content h5 {
  font-size: 18px;
  color: var(--color-1c1c1b)
}

.blog-content p.description {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}

.single-blog-card a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}

.single-blog-card h3{
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b)
}

.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}

/* Blog Part Ends Here */

/* Home Page Ends Here */

/* Product Card */

.product-card {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.product-card .product-image img:last-child {
  display: none;
}

.product-card .product-image {
  height: 270px;
}

.product-card .product-image img {
  object-fit: contain;
}


.product-card:hover .action-bar{
  right: 15px;
}

.product-card:hover .product-image > img:nth-child(1) {
  display: none;
  /* height: 270px; */

}

.product-card:hover .product-image > img:last-child {
  display: block;
  /* height: 270px; */
}

.product-card .product-details h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}

.product-card .product-details h5 {
  font-size: 15px;
  color: var(--color-1c1c1b);

}

.product-card .product-details p.price {
  font-size: 16px;
  color: var(--color-1c1c1b);
}

.product-card p.description {
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;;
}

.action-bar{
  top: 10%;
  right: -23px;
  transition: 0.3s; 
}

.wishlist-icon {
  top: 2%;
  right: 17px;
}

/* Product Card Ends Here */

/* Special Product */

.special-product-card {
  padding: 20px 10px;
  border-radius: 10px;
  background-color: white;
}

.special-product-card .title {
  overflow-wrap: break-word;
}

/* Special Product Ends Here */

/* Famous Products */

.famous-card .famous-content {
  top: 10%;
  left: 10%;
}

.famous-card img {
  border-radius: 10px;
}

.famous-content h5 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: white;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.famous-content h6 {
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  color: white;
}

.famous-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  color: white;
}

/* Famous Products End Here */

/* Our Store*/

.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 15px 15px 25px;
}

.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}

.filter-card ul, 
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}

.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}

.filter.card .form-check-input:checked {
  background-color: var(--color-febd69);
  border-color: var(--color-febd69);
}

.sub-title{
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-top: 15px;
  margin-bottom: 20px;
}

.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.colors li{
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

.random-products h5 {
  font-size: 14px;
  margin-bottom: 8px;
}

.random-products:first-child {
  border-bottom: 1px solid var(--color-ededed);
}

.filter-sort-grid {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
}

.grid img {
  width: 35px;
  height: 35px;
  background-color: var(--color-f2f2f2);
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}

.gr-12 {
  width: 100%;
}

.gr-6 {
  width: 48%;
}

.gr-4 {
  width: 32%;
}

.gr-3 {
  width: 24%;
}

.gr-12 .product-card {
  display: flex;
  gap: 20px
}

.gr-6 .product-image {
  width: 270px;
}

.gr-6 .product-image img {
  margin: auto;
  display: block;
}

.gr-12 .product-card .product-image {
  width: 270px;
}

.gr-12 .product-card .product-image {
  height: auto;
}

.gr-12 .action-bar {
  top: 17%;
}

/* Our Store Ends Here */

/* Contact Page */

.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
}

.contact-inner-wrapper > div {
  width: 48%; 
}

.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}

.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}

.contact-inner-wrapper ul {
  list-style: none;
}

.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}

/* Contact Page Ends Here */

/* Control Product Page */

.compare-product-card {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.compare-product-card .cross,
.wishlist-card .cross {
  top: 15px;
  right: 15px;
  width: 25px;
  cursor: pointer;
  padding: 3px;
}

.compare-product-details h5.title, 
.wishlist-card h5.title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}

.compare-product-details h6.price, 
.wishlist-card h6.price {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}

.compare-product-card .product-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Control Product Page Ends Here */

/* Login Page */

.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}

.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}

.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}

.auth-card .form-control {
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}

.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b)
}

.signup {
  background-color: var(--color-febd69);
  color: var(--color-131921);
  border: none;
}

.signup:hover {
  background-color: var(--color-131921);
  color: white;
}

/* Login Page Ends Here */

/* Policy Pages */

.policy {
  background-color: white;
  padding: 30px;
}

/* Policy Pages End Here */

/* Main Product Page */

.description-wrapper h4,
.reviews-wrapper h3 {
  font-size: 26px;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}

.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}

.review-inner-wrapper {
  background-color: white;
  padding: 30px;
}

.review-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.review-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}

.review-head p,
.review-head a {
  font-size: 14px;
  color: var(--color-777777);
}

.review-form h4 {
  font-size: 16px;
  color: var(--color-777777)
}

.review-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.review:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.reviews p {
  font-size: 14px;
  color: var(--color-777777);

}

.main-product-image {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.main-product-image > div {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.main-product-image img {
  width: 100% !important;
  height: 600px !important;
  object-fit: cover;
}

.other-product-images {
  background-color: white;
  padding: 20px;
}

.other-product-images > div {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 20px;
  width: 48%;
}

.main-product-image .js-image-zoom__zoomed-image {
  left: 50px !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}

.main-product-details {
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.main-product-details h3.title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}

.main-product-details p.price {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.main-product-details a.review-btn,
.t-review {
  color: var(--color-777777);
  font-size: 13px;
}

.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}

.product-data {
  font-size: 13px;
  color: var(--color-777777);
  margin-bottom: 0px;
}

.main-product-details a {
  font-size: 14px;
  color: var(--color-777777)
}

/* Main Product Page Ends Here */

/* Cart Page */

.cart-header, 
.cart-data {
  border-bottom: 1px solid #eaeaea;
}

.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}

.cart-col-1 {
  width: 40%;
}

.cart-col-1 p{
  color: var(--color-777777);
  font-size: 14px;
}

.cart-col-2 {
  width: 10%;
}

.cart-col-3 {
  width: 15%;
}

.cart-col-4 {
  width: 15%;
}

.cart-data h5.price {
  font-size: 14px;;
  color: var(--color-1c1c1b)
}

/* Cart Page Ends Here */

/* Shipping Page */

.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}

.total-price {
  font-size: 16px;
  color: var(--color-777777);
}

/* Shipping Page Ends Here */

.error {
  color: red;
  font-size: 12px;
}